import {
  SaveStudentFileBody,
  StudentFileResponse,
} from '../../types/studentFileSectionTypes';
import {
  SustainerBody,
  SustainerStepResponse,
} from '../../types/sustainerTypes';
import {
  CurrentStepResponse,
  StudentTuitionContinuityStatusResponse,
} from '../../types/tuitionContinuity';
import { TuitionForbiddenEvent } from '../../utils/tuitionForbiddenEvent';
import { FilesUpload } from '../../views/tuitionContinuity/steps/signDocumentation/parts/signDocumentationTypes';
import {
  TariffCalculateResponse,
  TariffData,
  TariffPaymentTypeCodesEnum,
} from '../../views/tuitionContinuity/steps/tariff/parts/tariffTypes';
import { TUITION_CONTINUITY } from '../endpoints';
import request, { RequestOptionsType } from '../request';

const tcRequest = async <T>(url: string, options: RequestOptionsType = {}) => {
  const response = await request<T>(url, options);
  const { error } = response;
  if (error && error.data.statusCode === 403) {
    window.dispatchEvent(new TuitionForbiddenEvent());
  }
  return response;
};

export const requestStudentTuitionContinuityStatus = (
  studyPlanEnrollmentId: number,
) => {
  return tcRequest<StudentTuitionContinuityStatusResponse>(
    TUITION_CONTINUITY.STUDENT_STATUS(studyPlanEnrollmentId),
    {
      method: 'get',
    },
  );
};
export const requestTuitionContinuityCurrentStep = (
  studyPlanEnrollmentId: number | string,
) =>
  tcRequest<{ data: CurrentStepResponse }>(
    TUITION_CONTINUITY.CURRENT_STEP(studyPlanEnrollmentId),
    {
      method: 'get',
    },
  );

/**********************
 * FICHA DEL ESTUDIANTE
 *********************/

export const requestTuitionContinuityRecoverStudentFile = (
  studyPlanEnrollmentId: number | string,
) =>
  tcRequest<{ data: StudentFileResponse }>(
    TUITION_CONTINUITY.RECOVER_STUDENT_FILE(studyPlanEnrollmentId),
    { method: 'get' },
  );

export const requestTuitionContinuitySaveStudentFile = (
  studyPlanEnrollmentId: number | string,
  data: SaveStudentFileBody,
) =>
  tcRequest<{ data: any }>(
    TUITION_CONTINUITY.SAVE_STUDENT_FILE(studyPlanEnrollmentId),
    { method: 'post', data },
  );

/**********************
 * ARANCEL Y CUOTAS
 *********************/

export const getTariffInfo = async (studyPLanEnrollmentId: number | string) => {
  const url = TUITION_CONTINUITY.TARIFF_STEP(studyPLanEnrollmentId);
  return tcRequest<TariffData>(url);
};

export const getTariffInfoCalculate = async (
  studyPLanEnrollmentId: number,
  data: {
    tariffDownPayment: number;
    installments: number;
    payday: number;
    tariffPaymentMethod?: string;
    tariffPaymentType: TariffPaymentTypeCodesEnum;
  },
) => {
  const url = TUITION_CONTINUITY.TARIFF_CALCULATE(studyPLanEnrollmentId);
  return tcRequest<TariffCalculateResponse>(url, {
    method: 'POST',
    data,
  });
};

export const saveTariffInfo = (
  studyPLanEnrollmentId: number | string,
  data: {
    tariffDownPayment: number;
    installments: number;
    payday: number;
    tariffPaymentMethod?: string;
    tariffPaymentType: TariffPaymentTypeCodesEnum;
  },
) => {
  const url = TUITION_CONTINUITY.TARIFF_STEP_SAVE(studyPLanEnrollmentId);
  return tcRequest<any>(url, {
    method: 'POST',
    data,
  });
};

/**********************
 * DOCUMENTACION
 *********************/

export const getSignDocumentationInfo = async (
  studyPlanEnrollmentId: number | string,
) => {
  const url = TUITION_CONTINUITY.SIGN_DOCUMENTATION_STEP(studyPlanEnrollmentId);
  return tcRequest<{
    documents: {
      document: {
        id: number;
        code: string;
        name: string;
        hint: string | null;
        description: string | null;
        icon: string | null;
        commentRequired: boolean;
        generated: boolean;
        uploadMethod: 'default' | 'signature' | 'confirmation';
        required: boolean;
      };
      uploaded: boolean;
      downloaded: boolean;
      requiredDocumentId?: number | null;
    }[];
  }>(url);
};

export const getSignDocumentationDocumentGenerated = async (
  studyPlanEnrollmentId: number | string,
  code: string,
) => {
  const url = TUITION_CONTINUITY.SIGN_DOCUMENTATION_DOCUMENT_GENERATED(
    studyPlanEnrollmentId,
  );
  console.log({ code });
  return tcRequest<{ url: string }>(url, {
    method: 'get',
    params: { code },
  });
};

/**
 * Toma un nombre de documento y un objeto de valores, y devuelve una promesa que se resuelve en la
 * respuesta de una solicitud POST al servidor.
 * @param {GeneralTypes} documentName - Tipos de documentos del paso de documentación,
 * @param {FilesUpload} values - compuesto de names y data (datos del formulario)
 * @returns El regreso es una promesa.
 */
export const uploadPostulationProcessDocument = ({
  tuitionContinuityProcessId,
  uploadMethod,
  code,
  values,
}: {
  tuitionContinuityProcessId: number | string;
  uploadMethod: string;
  code: string;
  values?: FilesUpload;
}) => {
  let url = TUITION_CONTINUITY.SIGN_DOCUMENTATION_UPLOAD_DOCUMENTATION(
    tuitionContinuityProcessId,
    uploadMethod,
  );
  let formData: FormData | Record<string, any>;
  if (['signature', 'confirmation'].includes(uploadMethod)) {
    formData = { type: code };
  } else {
    url = TUITION_CONTINUITY.SIGN_DOCUMENTATION_UPLOAD_DOCUMENTATION(
      tuitionContinuityProcessId,
      uploadMethod,
    );

    formData = new FormData();
    formData.append('type', code);

    if (values) {
      Object.entries(values).forEach(([key, value]) => {
        if (value instanceof File) formData.append(key, value, value.name);
        else formData.append(key, value);
      });
    }
  }

  return request(url, {
    method: 'post',
    data: formData,
  });
};

export const saveSignDocumentationInfo = (
  studyPlanEnrollmentId: number | string,
) => {
  const url = TUITION_CONTINUITY.SIGN_DOCUMENTATION_STEP_SAVE(
    studyPlanEnrollmentId,
  );
  return tcRequest<any>(url, {
    method: 'POST',
  });
};

/**********************
 * FICHA DEL SUSTENTADOR
 *********************/
export const getSustainer = (studyPlanEnrollmentId: number | string) =>
  tcRequest<{ data: SustainerStepResponse }>(
    TUITION_CONTINUITY.RECOVER_SUSTAINER(studyPlanEnrollmentId),
    { method: 'get' },
  );

export const saveSustainer = (
  studyPlanEnrollmentId: string | number,
  data: SustainerBody,
) =>
  tcRequest<any>(TUITION_CONTINUITY.SAVE_SUSTAINER(studyPlanEnrollmentId), {
    method: 'post',
    data,
  });
