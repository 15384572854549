/**
 * Devuelve una matriz de objetos con el nombre del input y la ruta del idioma para el label
 * @param {GeneralTypes} documentName - Todos los tipos de documentos
 * @returns Una matriz de objetos con las propiedades name y labelPath.
 */
export function getInputs(
  commentRequired: boolean,
): {
  name: string;
  labelPath: string;
  type: string;
}[] {
  const inputs = [];

  inputs.push({
    name: 'doc',
    labelPath: 'tuitionContinuity.signDocumentation.attachment.file',
    type: 'file',
  });

  if (commentRequired) {
    inputs.push({
      name: 'comment',
      labelPath: '',
      type: 'textArea',
    });
  }
  return inputs;
}
