import { CheckInput, Select, TextInput } from '@octano/global-ui';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import SectionTitle from '../../../../../components/text/SectionTitle';
import { useParameters } from '../../../../../hooks/useParameters';
import { useValidations } from '../../../../../hooks/useValidations';
import { DataOwnSustainer } from '../../../../../types/sustainerTypes';
import {
  RELATIONSHIP_OWN_SUSTAINER,
  maritalStatusOptions,
  relationshipOptions,
  sexCIOptions,
} from '../../../../../utils/selectOptions';

function PersonalInformation({
  dataOwnSustainer,
  withPassport = false,
}: {
  dataOwnSustainer?: DataOwnSustainer;
  withPassport?: boolean;
}) {
  const { control, watch } = useFormContext();
  const prefix = 'tuitionContinuity.steps.sustainer.personalInformation';
  const { t } = useTranslation();
  const {
    msgValidations,
    validateTextNotEmpty,
    validateRut,
  } = useValidations();
  const ownSustainer = watch('ownSustainer');
  const { countryOptions, nationalityOptions, loadCountries } = useParameters();

  useEffect(() => {
    loadCountries();
  }, [loadCountries]);

  const filteredRelationshipOptions = useMemo(
    () =>
      relationshipOptions.filter(
        (row) =>
          (ownSustainer && row.value === RELATIONSHIP_OWN_SUSTAINER) ||
          row.value !== RELATIONSHIP_OWN_SUSTAINER,
      ),
    [ownSustainer],
  );

  const hasPassport = watch('hasPassport');

  return (
    <>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
        <Col xs={12} className="pb-4">
          <span className="fs-18 text-light fw-300">
            {t(`${prefix}.subtitle`)}
          </span>
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12}>
          <CheckInput
            name="ownSustainer"
            label={t(`${prefix}.ownSustainer`)}
            control={control}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="relationship"
            label={t(`${prefix}.relationship`)}
            options={filteredRelationshipOptions}
            isSearchable={false}
            control={control}
            rules={{ required: msgValidations.required }}
            disabled={ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="occupation"
            label={t(`${prefix}.occupation`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="names"
            label={t(`${prefix}.names`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
            disabled={ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="paternalLastName"
            label={t(`${prefix}.paternalLastName`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
            disabled={ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="maternalLastName"
            label={t(`${prefix}.maternalLastName`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
            disabled={ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Row>
            {(ownSustainer && !dataOwnSustainer?.passportNumber) ||
            (!ownSustainer && !hasPassport) ? (
              <Col xs={12}>
                <TextInput
                  name="rut"
                  label={t(`${prefix}.rut`)}
                  control={control}
                  formatter="rut"
                  rules={{
                    validate: { empty: validateTextNotEmpty, rut: validateRut },
                  }}
                  shouldUnregister={true}
                  disabled={ownSustainer === true}
                />
              </Col>
            ) : (
              <>
                <Col xs={12} md={6} className="pr-md-1">
                  <Select
                    name="passportCountry"
                    label={t(`${prefix}.passportCountry`)}
                    control={control}
                    options={countryOptions}
                    shouldUnregister={true}
                    isClearable={false}
                    disabled={ownSustainer === true}
                  />
                </Col>
                <Col xs={12} md={6} className="pl-md-1">
                  <TextInput
                    name="passportNumber"
                    label={t(`${prefix}.passportNumber`)}
                    control={control}
                    shouldUnregister={true}
                    disabled={ownSustainer === true}
                  />
                </Col>
              </>
            )}
            {!ownSustainer && withPassport && (
              <Col xs={12} className="mb-n5">
                <CheckInput
                  name="hasPassport"
                  label={t(`${prefix}.checkPassport`)}
                  control={control}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="nationality"
            label={t(`${prefix}.nationality`)}
            options={nationalityOptions}
            isSearchable
            control={control}
            rules={{ required: msgValidations.required }}
            disabled={ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="maritalStatus"
            label={t(`${prefix}.maritalStatus`)}
            options={maritalStatusOptions}
            isSearchable={false}
            control={control}
            rules={{ required: msgValidations.required }}
            disabled={ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="sexID"
            label={t(`${prefix}.sexID`)}
            options={sexCIOptions}
            isSearchable={false}
            control={control}
            rules={{ required: msgValidations.required }}
            disabled={ownSustainer === true}
          />
        </Col>
      </Row>
    </>
  );
}

export default PersonalInformation;
