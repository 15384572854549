const DiscountsTooltip = ({
  discounts,
}: {
  discounts: {
    id: string | number;
    name: string;
    code: string;
    percentage: number;
    amount: number;
  }[];
}) => {
  return (
    <>
      {discounts.map((discount) => (
        <p key={discount.id} className={'mb-1'}>
          {discount.name} - {discount.percentage}% - {discount.code}
        </p>
      ))}
    </>
  );
};

export default DiscountsTooltip;
