export interface Quota {
  id: number;
  quota: number;
  description: string;
}

export interface QuotaSimulationItem {
  startDate: string;
  endDate: string;
  index: number;
  amount: number;
  interests: number;
  description: string;
}

export interface PayDay {
  value: number;
  description: string;
}
export interface TariffData {
  studyPlan?: string;
  campus?: string;
  schedule?: string;
  hasDiscount: boolean;
  feeAmount: number;
  feeDiscountsAmount: number;
  feeNetAmount: number;
  feeDiscounts: {
    id: number;
    name: string;
    code: string;
    percentage: number;
    amount: number;
  }[];
  quotas: Quota[];
  payday: PayDay;
  installmentsResume?: TariffCalculateResponse;
}

export interface TariffCalculateResponse {
  total: number;
  installments: QuotaSimulationItem[];
  showInterestsDetails: boolean;
}

export enum TariffPaymentTypeCodesEnum {
  CO3 = 'CO3', //contado a 3 cuotas
  CO = 'CO', //contado
  CR = 'CR', //credito
  CRI = 'CRI', //credito indirecto
}

export enum InterestsCalculatorVariant {
  ProrateInterest = 'proratear',
  SkipInterest = 'saltar',
}

export enum QuotaRoundingMethod {
  RoundToBalanced = 'balanceado',
  RoundToSimple = 'simple',
  NotRound = 'no_redondear',
  RoundToTruncateToEnd = 'truncar_ajustar_final',
}
