import { useCallback, useEffect, useState } from 'react';

import { getTariffInfoCalculate } from '../../../../../api/requests/tuitionContinuity';
import DisplayError from '../../../../../components/DisplayError';
import TariffTable from './TariffTable';
import {
  PayDay,
  TariffCalculateResponse,
  TariffPaymentTypeCodesEnum,
} from './tariffTypes';

export interface TariffTableLoaderProps {
  studyPlanEnrollmentId: number;
  quota?: number;
  payday: PayDay;
  resume?: TariffCalculateResponse;
  tariffDownPayment: number;
  tariffPaymentMethod: string;
  tariffPaymentType: TariffPaymentTypeCodesEnum;
}

export function TariffTableLoader(props: TariffTableLoaderProps) {
  const {
    studyPlanEnrollmentId,
    quota,
    payday,
    tariffDownPayment,
    tariffPaymentMethod,
    tariffPaymentType,
  } = props;

  const [loading, setLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState<
    'CONNECTION' | 'HTTP_ERROR' | string
  >();

  const [data, setData] = useState<TariffCalculateResponse | undefined>(
    props.resume,
  );

  /* Función que se llama cuando se monta el componente. Trae la info utilizada en la vista */
  const getTariffQuotaInfo = useCallback(async () => {
    if (!quota) {
      setData(undefined);
      setErrorLoading(undefined);
      setLoading(false);
      return;
    }

    setLoading(true);

    const tariffResponse = await getTariffInfoCalculate(studyPlanEnrollmentId, {
      tariffDownPayment,
      installments: quota,
      payday: payday.value,
      tariffPaymentMethod,
      tariffPaymentType,
    });

    if (tariffResponse.data) {
      setData(tariffResponse.data);
      setErrorLoading(undefined);
    }
    if (tariffResponse.error) {
      setErrorLoading(tariffResponse.error.code);
    }
    setLoading(false);
  }, [
    studyPlanEnrollmentId,
    quota,
    payday,
    setErrorLoading,
    setLoading,
    tariffDownPayment,
    tariffPaymentType,
    tariffPaymentMethod,
  ]);

  useEffect(() => {
    getTariffQuotaInfo();
  }, [getTariffQuotaInfo]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => getTariffQuotaInfo()}
        loadingAction={loading}
      />
    );
  }

  return (
    <TariffTable
      quotas={data?.installments ?? []}
      loading={loading}
      showInterestsDetails={!!data?.showInterestsDetails}
      tariffDownPayment={0}
    />
  );
}
