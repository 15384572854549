import { Button, Select, SelectOptionType, addToast } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';

import { saveTariffInfo } from '../../../../../api/requests/tuitionContinuity';
import { useStepState } from '../../../../../hooks/useStepState';
import { useValidations } from '../../../../../hooks/useValidations';
import TariffDisplayData from './TariffDisplayData';
import { TariffTableLoader } from './TariffTableLoader';
import { PayDay, TariffData, TariffPaymentTypeCodesEnum } from './tariffTypes';

export interface TariffProps extends TariffData {
  studyPlanEnrollmentId: number;
}

type FormFields = {
  tariffDownPayment: number;
  tariffPaymentMethod: string;
  tariffPaymentType: TariffPaymentTypeCodesEnum;
  quota: SelectOptionType & { quota: number };
  payday: PayDay;
};

export default function Tariff({
  studyPlanEnrollmentId,
  ...props
}: TariffProps) {
  const prefix = 'tuitionContinuity.tariffQuotas';
  const { nextStep } = useStepState();

  const methods = useForm<FormFields>({
    defaultValues: {
      tariffDownPayment: 0,
      tariffPaymentMethod: 'TC',
      tariffPaymentType: TariffPaymentTypeCodesEnum.CR,
      payday: props.payday,
    },
  });
  const history = useHistory();
  const { t } = useTranslation();
  const { msgValidations } = useValidations();

  const quotas = props.quotas;

  const { handleSubmit, formState, control, watch } = methods;

  const [
    quota,
    payday,
    tariffPaymentMethod,
    tariffPaymentType,
    tariffDownPayment,
  ] = watch([
    'quota',
    'payday',
    'tariffPaymentMethod',
    'tariffPaymentType',
    'tariffDownPayment',
  ]);

  const onSubmit = useCallback(
    async (values: FormFields) => {
      if (!values.quota.value) {
        return;
      }
      const { data, error } = await saveTariffInfo(studyPlanEnrollmentId, {
        tariffDownPayment: values.tariffDownPayment,
        installments: Number(values.quota.value),
        payday: values.payday.value,
        tariffPaymentMethod: values.tariffPaymentMethod,
        tariffPaymentType: values.tariffPaymentType,
      });
      if (data) {
        addToast({
          icon: 'check',
          color: 'success',
          text: t(`${prefix}.successOnSaveTariff`),
        });
        nextStep();
      }
      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.errorOnSaveTariff`),
        });
      }
    },
    [studyPlanEnrollmentId, nextStep, t],
  );

  const options = useMemo(() => {
    return quotas.map<SelectOptionType>(
      ({ id: value, description: label, quota }) => ({
        label,
        value,
        quota,
      }),
    );
  }, [quotas]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TariffDisplayData tariffData={props}>
        <Select
          name="quota"
          label={t(`${prefix}.displayData.quotas`)}
          isClearable={false}
          options={options}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </TariffDisplayData>

      <TariffTableLoader
        studyPlanEnrollmentId={studyPlanEnrollmentId}
        quota={quota?.quota}
        payday={payday}
        tariffPaymentMethod={tariffPaymentMethod}
        tariffPaymentType={tariffPaymentType}
        tariffDownPayment={tariffDownPayment}
        resume={props.installmentsResume}
      />

      <Row className="pt-5 mt-5 justify-content-end">
        <Col xs={12} lg={4} className="pb-2 order-2 order-lg-1">
          <Button
            type="button"
            outlined
            onClick={() => history.push('/tuition-continuity-process')}
            text={t(`common.actions.cancel`)}
            loading={formState.isSubmitting}
            fullwidth
          />
        </Col>

        <Col xs={12} lg={4} className="pb-2 order-1 order-lg-2">
          <Button
            type="submit"
            text={t(`common.actions.next`)}
            loading={formState.isSubmitting}
            fullwidth
          />
        </Col>
      </Row>
    </Form>
  );
}
